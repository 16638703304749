import dayjs from 'dayjs';
const isValidOcrDate = datedayjs => {
  if (!datedayjs.isValid()) return false;
  const min = dayjs('1900');
  const max = dayjs('3000');
  return datedayjs > min && datedayjs < max;
};

const parseTimeStr = date => {
  if (!date) return null;
  date = dayjs(date);
  return isValidOcrDate(date) ? date.valueOf() : null;
};
const isHuoyunCategory = cate => {
  if (!cate) return false;
  return cate.indexOf('货运') > -1 || cate.indexOf('道路货物运输') > -1;
};

const parseValidDate = str => {
  if (!str) return null;
  const parsedStr = str.replace(/[年月-]/g, '/').replace(/[至日到]/g, '');
  return parseTimeStr(parsedStr);
};

export const getDateFromQualificationOcr = list => {
  const res = {
    qualificationLicenceStartDate: null,
    qualificationLicenceEndDate: null
  };
  if (!list || !Array.isArray(list) || !list.length) return res;
  const target = list.find(it => isHuoyunCategory(it.category));
  if (target) {
    const start = parseValidDate(target.issue_date);
    const end = parseValidDate(target.expiry_date);
    if (start && end) {
      res.qualificationLicenceStartDate = start;
      res.qualificationLicenceEndDate = end;
    }
  }
  return res;
};

export const formatQualificationOcr = res => {
  if (!res) return null;
  const { name, certificateNumber, qualificationCategoryList, ...rest } = res;
  const { qualificationLicenceStartDate, qualificationLicenceEndDate } = getDateFromQualificationOcr(
    qualificationCategoryList
  );
  return {
    name,
    qualificationNumber: certificateNumber,
    qualificationLicenceStartDate,
    qualificationLicenceEndDate,
    ...rest
  };
};

export const parseWeightStr = num => {
  if (!num) return null;
  const weight = parseInt(num);
  if (!weight || isNaN(weight)) return null;

  return weight;
};
export const isTrailerType = vehicleType => {
  if (!vehicleType || typeof vehicleType !== 'string') return false;
  return vehicleType.indexOf('牵引') > -1;
};
export const formatWeightOcr = data => {
  let { loadQuality, totalMass, curbWeight, totalQuasiMass, vehicleType } = data;
  const isTrailer = isTrailerType(vehicleType);
  loadQuality = parseWeightStr(loadQuality);
  totalMass = parseWeightStr(totalMass);
  curbWeight = parseWeightStr(curbWeight);
  totalQuasiMass = parseWeightStr(totalQuasiMass);
  if (isTrailer) {
    loadQuality = totalQuasiMass || loadQuality || null;
    if (curbWeight && loadQuality) {
      totalMass = curbWeight + loadQuality;
    } else {
      totalMass = null;
    }
  } else {
    loadQuality = loadQuality || totalQuasiMass || null;
  }

  return {
    totalWeight: totalMass,
    loadWeight: loadQuality,
    curbWeight
  };
};
